import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'

interface Body {
  uuid: string
  advertised: number
  departureDate: string
  from: string
  to: string
  comment: string
}

export function hideDeviationVehicleProposal(
  body: Body
): Promise<AxiosResponse<void>> {
  return instance.post(`/deleted-deviation-vehicle-proposal`, body)
}
