
import { computed, defineComponent, provide } from 'vue'
import DeviationVehicleTable from '@/components/deviation/deviation-vehicle/DeviationVehicleTable.vue'
import { useDeviationVehicle } from '@/composable/useDeviationVehicle'
import { useModal } from '@/composable/useModal'
import DeviationDeleteModal from '@/components/deviation/DeviationDeleteModal.vue'
import DeviationVehicleFormModal from '@/components/deviation/deviation-vehicle/DeviationVehicleFormModal.vue'
import DeviationFilterModal from '@/components/deviation/DeviationFilterModal.vue'
import { useProfile } from '@/composable/useProfile'
import { addColumns } from '@/common/deviationAddColumns'
import { useDeviationVehicleProposal } from '@/composable/useDeviationVehicleProposal'
import DeviationVehicleHideModal from '@/components/deviation/deviation-vehicle/DeviationVehicleHideModal.vue'

export default defineComponent({
  name: 'VehicleDeviations',

  components: {
    DeviationVehicleTable,
    DeviationDeleteModal,
    DeviationVehicleFormModal,
    DeviationFilterModal,
    DeviationVehicleHideModal,
  },

  setup() {
    const { can } = useProfile()
    const {
      data: vehicleDeviations,
      loading: loadingStateDeviationVehicle,
      fetchAll,
      period,
    } = useDeviationVehicle()
    const {
      fetchAll: fetchAllProposals,
      data: deviationVehicleProposals,
      loading: loadingStateDeviationVehicleProposal,
    } = useDeviationVehicleProposal()
    const deviationVehicleFormModal = useModal()
    const deviationVehicleDeleteModal = useModal()
    const deviationVehicleHideModal = useModal()
    provide('deviation-vehicle-form-modal', deviationVehicleFormModal)
    provide('deviation-delete-modal', deviationVehicleDeleteModal)
    provide('deviation-vehicle-hide-modal', deviationVehicleHideModal)

    fetchAllProposals(period.value)
    fetchAll(period.value)

    const data = computed(() => {
      return [...deviationVehicleProposals.value, ...vehicleDeviations.value]
    })

    const loading = computed(() => {
      return (
        loadingStateDeviationVehicleProposal.value.getAll ||
        loadingStateDeviationVehicle.value.getAll
      )
    })

    return {
      addColumns,
      data,
      loading,
      deviationVehicleFormModal,
      deviationVehicleDeleteModal,
      can,
      deviationVehicleHideModal,
    }
  },
})
