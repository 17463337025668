<template>
  <q-page>
    <q-dialog v-model="deviationVehicleFormModal.state.show">
      <DeviationVehicleFormModal
        show-event-input
        v-if="deviationVehicleFormModal.state.show"
      />
    </q-dialog>
    <q-dialog v-model="deviationVehicleDeleteModal.state.show">
      <DeviationDeleteModal v-if="deviationVehicleDeleteModal.state.show" />
    </q-dialog>
    <q-dialog
      v-model="deviationVehicleHideModal.state.show"
      :persistent="false"
    >
      <DeviationVehicleHideModal
        show-event-input
        v-if="deviationVehicleHideModal.state.show"
      />
    </q-dialog>
    <DeviationVehicleTable
      :addColumns="addColumns"
      :loading="loading.getAll"
      height="calc(100vh - 50px - 68px)"
      :deviation-vehicles="data"
    >
      <template v-slot:top-left-buttons>
        <h6 class="q-ma-none">Fordonsavvikelser</h6>
        <q-btn
          v-if="can('deviationManagement.deviationVehicle.create')"
          icon-right="mdi-plus-circle-outline"
          class="q-ml-md"
          label="Lägg till"
          color="primary"
          @click="() => deviationVehicleFormModal.openModal({ mode: 'create' })"
        />
      </template>

      <template v-slot:top-right-buttons>
        <DeviationFilterModal deviation-type="deviationVehicle" :max-days="7" />
      </template>
    </DeviationVehicleTable>
  </q-page>
</template>

<script lang="ts">
import { computed, defineComponent, provide } from 'vue'
import DeviationVehicleTable from '@/components/deviation/deviation-vehicle/DeviationVehicleTable.vue'
import { useDeviationVehicle } from '@/composable/useDeviationVehicle'
import { useModal } from '@/composable/useModal'
import DeviationDeleteModal from '@/components/deviation/DeviationDeleteModal.vue'
import DeviationVehicleFormModal from '@/components/deviation/deviation-vehicle/DeviationVehicleFormModal.vue'
import DeviationFilterModal from '@/components/deviation/DeviationFilterModal.vue'
import { useProfile } from '@/composable/useProfile'
import { addColumns } from '@/common/deviationAddColumns'
import { useDeviationVehicleProposal } from '@/composable/useDeviationVehicleProposal'
import DeviationVehicleHideModal from '@/components/deviation/deviation-vehicle/DeviationVehicleHideModal.vue'

export default defineComponent({
  name: 'VehicleDeviations',

  components: {
    DeviationVehicleTable,
    DeviationDeleteModal,
    DeviationVehicleFormModal,
    DeviationFilterModal,
    DeviationVehicleHideModal,
  },

  setup() {
    const { can } = useProfile()
    const {
      data: vehicleDeviations,
      loading: loadingStateDeviationVehicle,
      fetchAll,
      period,
    } = useDeviationVehicle()
    const {
      fetchAll: fetchAllProposals,
      data: deviationVehicleProposals,
      loading: loadingStateDeviationVehicleProposal,
    } = useDeviationVehicleProposal()
    const deviationVehicleFormModal = useModal()
    const deviationVehicleDeleteModal = useModal()
    const deviationVehicleHideModal = useModal()
    provide('deviation-vehicle-form-modal', deviationVehicleFormModal)
    provide('deviation-delete-modal', deviationVehicleDeleteModal)
    provide('deviation-vehicle-hide-modal', deviationVehicleHideModal)

    fetchAllProposals(period.value)
    fetchAll(period.value)

    const data = computed(() => {
      return [...deviationVehicleProposals.value, ...vehicleDeviations.value]
    })

    const loading = computed(() => {
      return (
        loadingStateDeviationVehicleProposal.value.getAll ||
        loadingStateDeviationVehicle.value.getAll
      )
    })

    return {
      addColumns,
      data,
      loading,
      deviationVehicleFormModal,
      deviationVehicleDeleteModal,
      can,
      deviationVehicleHideModal,
    }
  },
})
</script>
